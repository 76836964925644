<template>
  <div class="home">
    <div ref="home">
      <Navbar @scrollMeTo="scroll"/>
    </div>
    <HomeImg />
    <div v-if="isMobile()" ref="about">
      <AboutUsM @scrollMeTo="scroll"/>
    </div>
    <div v-else ref="about">
      <AboutUs @scrollMeTo="scroll"/>
    </div>
    <div v-if="isMobile()" ref="team">
      <TeamM @scrollMeTo="scroll"/>
    </div>
    <div v-else ref="team">
      <Team @scrollMeTo="scroll"/>
    </div>
    <div v-if="isMobile()" ref="philo">
      <PhilosophyM @scrollMeTo="scroll"/>
    </div>
    <div v-else ref="philo">
      <Philosophy @scrollMeTo="scroll"/>
    </div>
    <div v-if="isMobile()" ref="contact">
      <ContactM @scrollMeTo="scroll"/>
    </div>
    <div v-else ref="contact">
      <Contact @scrollMeTo="scroll"/>
    </div>
    <div class="goTop">
      <span @click="scroll('home')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          fill="currentColor"
          class="bi bi-arrow-up-circle-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
          />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import HomeImg from '@/components/HomeImg.vue'
import AboutUs from '@/components/AboutUs.vue'
import AboutUsM from '@/components/mobile/AboutUsM.vue'
import Team from '@/components/Team.vue'
import TeamM from '@/components/mobile/TeamM.vue'
import Philosophy from '@/components/Philosophy.vue'
import PhilosophyM from '@/components/mobile/PhilosophyM.vue'
import Contact from '@/components/Contact.vue'
import ContactM from '@/components/mobile/ContactM.vue'

export default {
  name: 'Home',
  components: {
    Navbar,
    HomeImg,
    AboutUs,
    AboutUsM,
    Team,
    TeamM,
    Philosophy,
    PhilosophyM,
    Contact,
    ContactM,
  },
  methods:{
    scroll(refName) {
        var element = this.$refs[refName];
        var top = element.offsetTop;
        window.scrollTo(0, top);
    },
    isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
    },
  },
}
</script>
<style scoped>
.home{
  position: relative;
}
.goTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
}
.bi-arrow-up-circle-fill {
  color: rgba(113, 112, 112, 0.199);
  cursor: pointer;
}
.bi-arrow-up-circle-fill:hover {
  color: #cd1a1b;
}
</style>