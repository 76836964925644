<template>
  <div class="section">
    <div class="container fluid">
      <div ref="about" class="aboutSection">
        <div class="row justify-content-between">
          <div class="col-sm-7">
            <h2>{{ $t("about.title") }}</h2>
            <div class="separator"></div>
            <h3>
              {{ $t("about.subtitle1") }}<br />
              <a href="https://www.bacs-oua.com/" target="_blank"
                >{{ $t("about.subtitle2") }}</a
              >
            </h3>
          </div>
          <div class="col-sm-5">
            <p class="text">
              {{ $t("about.text1") }}<br /><br />
              - {{ $t("about.text2") }}<br />
              - {{ $t("about.text3") }}<br />
              - {{ $t("about.text4") }}<br />
              - {{ $t("about.text5") }}<br /><br />

              {{ $t("about.text6") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script scoped>
export default {
  methods: {
    scrollMeTo(refName) {
      this.$emit("scrollMeTo", refName);
    },
  },
};
</script>
<style scoped>
.section {
  background-image: url(../assets/img/bgDark.png);
  position: relative;
}
.aboutSection {
  padding-top: 160px;
  padding-bottom: 160px;
}
a {
  color: #fff;
  text-decoration: none;
  border-bottom: 2px dashed #fff;
}
a:hover {
  color: #cd1a1b;
  border-bottom: 2px dashed #cd1a1b;
}
h2 {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  text-align: left;
  color: #fff;
}
h3 {
  font-family: "Roboto", sans-serif;
  font-size: 50px;
  font-weight: 900;
  text-align: left;
  line-height: 70px;
  color: #fff;
}
.separator {
  margin: 40px 0 40px 0;
  width: 80px;
  height: 9px;
  background-color: #cd1a1b;
}
.text {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #fff;
}
.goTop {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.bi-arrow-up-circle-fill {
  color: rgba(113, 112, 112, 0.199);
  cursor: pointer;
}
.bi-arrow-up-circle-fill:hover {
  color: #cd1a1b;
}
</style>