<template>
  <div id="nav">
    <!-- <Navbar /> -->
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
  </div>
  <router-view/>
</template>
<script>
/* import Navbar from '@/components/Navbar.vue' */

export default {
  components: {
    /* Navbar */
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  margin-top: 0;
}

</style>
