<template>
    <div class="bgImg">
        <div class="overImg">
        </div>
        <div class="row top">
            <div class="col1">
                <span>BROKER AIR CARGO SERVICES</span>
            </div>
        </div>
    </div>
</template>
<style>
    .bgImg{
        /* The image used */
        background-image: url(../assets/img/cargo.jpg);

        /* Full height */
        height: 700px;

        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        box-sizing: content-box;
        width: 100%;
        position: relative;
    }
    .overImg{
        /* Full height */
        height: 700px;

        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #16161669;

        z-index: 1;
    }
    .row {
        --bs-gutter-x: 0;
    }
    .col1{
        width: 100%;
        display: table;
    }
    .col1 span {
        vertical-align: middle;
        display: table-cell;
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        font-weight: 600;
        text-align: right;
        padding-right: 25px;
    }
    .top{
        position: absolute;
        top: 118px;
        z-index: 2;
        width: 100%;
        background-color: #cd1a1ab0;
        height: 40px;
    }
</style>