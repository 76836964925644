export default {
  "navbar.link.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
  "navbar.link.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABOUT US"])},
  "navbar.link.team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEAM"])},
  "navbar.link.philosophy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHILOSOPHY"])},
  "navbar.link.tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRACKING"])},
  "navbar.link.contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT"])},
  "about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABOUT US"])},
  "about.subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based in CDG and"])},
  "about.subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["West africa airports"])},
  "about.text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BACS offers Air Cargo Solutions to Airlines and Forwarders:"])},
  "about.text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GSA/GSSA"])},
  "about.text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSA"])},
  "about.text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP flight and cargo assistance staff trained IATA"])},
  "about.text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargo Charters"])},
  "about.text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide online services: quotation, booking, track and trace and taylormade services for our customers Airlines and Forwarders and basic non virtual services. "])},
  "team.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Team"])},
  "team.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skilled, trained and passionate by Air Cargo univers"])},
  "team.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We work to made and build the difference for all cargo and customers with very different requirements. We speak English, French, Chinese, Vietnamien and hope to add new languages and spirit soon."])},
  "team.charles.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MANAGING DIRECTOR"])},
  "team.yacouba.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AFRICA MANAGING DIRECTOR"])},
  "team.antoine.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AFRICA OPERATIONS DIRECTOR"])},
  "team.mai.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SALES MANAGER"])},
  "philosophy.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Philosophy"])},
  "philosophy.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovation combined with basis services "])},
  "philosophy.technology.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TECHNOLOGY"])},
  "philosophy.human.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUMAN"])},
  "philosophy.service.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SERVICE"])},
  "philosophy.technology.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BACS trust in AWERY software solutions powered by Vitaly with offers the most dynamic IT solutions for Air Cargo Aviation able to provide all services with the same efficient software for GSA, charter and Flight assistance services."])},
  "philosophy.human.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In our competitive business, we remains still trust in human relationship and combined our IT support with physical meeting with our customers and remains a large phone number available 24H a day."])},
  "philosophy.service.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are fully independent and provide additional services more than usual GSA current services : pick up, customs clearance, packing. "])},
  "contact.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT US"])},
  "contact.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have any questions? Feel free to leave us a message "])},
  "contact.french.adress1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CDG/LFPG Airport"])},
  "contact.french.adress2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CDG AIRPORT"])},
  "contact.ouagadougou.adress1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUA/DFFD Airport"])},
  "contact.form.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name*"])},
  "contact.form.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email*"])},
  "contact.form.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message*"])},
  "contact.form.send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "footer.legal.notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Notice"])}
}