export default {
  "navbar.link.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCUEIL"])},
  "navbar.link.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À PROPOS"])},
  "navbar.link.team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ÉQUIPE"])},
  "navbar.link.philosophy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHILOSOPHIE"])},
  "navbar.link.tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUIVI"])},
  "navbar.link.contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT"])},
  "about.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À PROPOS"])},
  "about.subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basé sur CDG et les"])},
  "about.subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aéroports d'Afrique de l'ouest"])},
  "about.text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BACS propose des solutions de fret aérien aux companies aériennes ainsi qu'aux transitaires:"])},
  "about.text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GSA/GSSA"])},
  "about.text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSA"])},
  "about.text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnel d'assistance vol et cargo VIP formé IATA"])},
  "about.text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charters Cargo"])},
  "about.text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous fournissons des services en ligne : devis, réservation, suivis \"track and trace\". Des services sur mesure pour nos clients, Compagnies aériennes et transitaires."])},
  "team.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre Équipe"])},
  "team.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compétents, formés et passionnés par l'univers du fret aérien"])},
  "team.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous travaillons afin de faire la différence pour toutes les cargaisons en prenant en compte des demandes clients aux exigences mutiples. Nous parlons anglais, français, chinois, vietnamien et espérons ajouter de nouvelles langues très prochainement."])},
  "team.charles.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIRECTEUR GÉNÉRAL"])},
  "team.yacouba.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIRECTEUR GÉNÉRAL AFRIQUE"])},
  "team.antoine.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIRECTEUR DES OPÉRATIONS AFRIQUE"])},
  "team.mai.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIRECTEUR COMMERCIAL"])},
  "philosophy.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre Philosophie"])},
  "philosophy.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovation combinée avec services de base"])},
  "philosophy.technology.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TECHNOLOGIE"])},
  "philosophy.human.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUMAIN"])},
  "philosophy.service.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SERVICE"])},
  "philosophy.technology.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BACS fait confiance aux solutions logicielles AWERY propulsées par Vitaly. Logiciel spécialisé dans la gestion du fret aérien, capable de fournir des prestations ultra performantes en terme de services GSA, d'affrètement et d'assistance en vol."])},
  "philosophy.human.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans notre secteur d'acitivité compétitif, nous croyons plus que tout dans la force de la relation humaine et combinons notre support informatique avec la rencontre physique de nos clients. Nous mettons également à disposition une assistance téléphonique 24H/24."])},
  "philosophy.service.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes totalement indépendants et fournissons des prestations supplémentaires aux services actuels de GSA : enlèvement, dédouanement, emballage."])},
  "contact.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT"])},
  "contact.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez des questions ? N'hésitez pas à nous laisser un message"])},
  "contact.french.adress1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aéroport CDG/LFPG"])},
  "contact.french.adress2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aéroport CDG"])},
  "contact.ouagadougou.adress1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aéroport OUA/DFFD"])},
  "contact.form.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom*"])},
  "contact.form.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre email*"])},
  "contact.form.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre message*"])},
  "contact.form.send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
  "footer.legal.notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions légales"])}
}