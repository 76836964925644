import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Particles from "particles.vue3";
import Toaster from "@meforma/vue-toaster";
import i18n from './i18n';
import VueCookies from 'vue-cookies';

createApp(App).use(i18n).use(VueCookies, { expire: '7d'}).use(store).use(router).use(Particles).use(Toaster).mount('#app')
